<template>
    <div class="list-div">
        <el-card class="el-card-mainview">
            <el-row>
                <el-col>
                    <el-button type="primary" @click="addCharacterDialogVisible = true">添加角色</el-button>
                </el-col>
            </el-row>
            <el-table :data="charactersList" stripe v-loading="Loading">
                   <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-row  v-loading="Tag2Loading" :class="['bdbottom' , i1 ===0 ? 'bdtop' : 'bdbottom','vcenter']" v-for="(item1,i1) in scope.row.authorities" 
                        :key="item1.id">
                            <el-col :span="5">
                                <el-tag type="danger"  closable @close="DeleteAuthorityByCharacter(scope.row.id, scope.row.authorities, item1)">
                                    {{item1.name}}
                                </el-tag>
                                <i class="el-icon-caret-right"></i>
                            </el-col>
                            <el-col :span="19">
                                <el-row :class="[i2 === 0 ? '':'bdtop','vcenter']" v-for="(item2,i2) in item1.authorities" :key="item2.id">
                                    <el-col :span="6">
                                        <el-tag type="success" closable @close="DeleteAuthorityByCharacter(scope.row.id, item1.authorities, item2)">
                                            {{item2.name}}
                                        </el-tag>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                    </template>
                </el-table-column>
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column label="描述" prop="description"></el-table-column> 
                <el-table-column label="操作" prop="option" width="300">
                    <template slot-scope="scope"> 
                        <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
                            <el-button type="primary" icon="el-icon-edit" @click="ShowModifyCharacterDialog(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip effect="dark" content="分配权限" placement="top" :enterable="false">
                            <el-button type="warning" icon="el-icon-setting" @click="ShowSetAuthorityDiaolog(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                            <el-button type="danger" icon="el-icon-delete" @click="DeleteCharacterInfo(scope.row.id)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <el-dialog title="添加角色" :visible.sync="addCharacterDialogVisible" width="30%" @close="AddCharacterDialogClosed" :close-on-click-modal="false"> 
            <el-form :model="addCharacterForm" :rules="addCharacterFormRules" ref="addCharacterFormRef" label-width="80px" v-loading="AdLoading">
                <el-form-item label="名称：" prop="Name">
                    <el-input v-model="addCharacterForm.Name"></el-input>
                </el-form-item>
                <el-form-item label="描述：" prop="Description">
                    <el-input v-model="addCharacterForm.Description"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addCharacterDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="AddCharacterInfo" :disabled="AdLoading">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="编辑角色" :visible.sync="modifyCharacterDialogVisible" width="30%" @close="ModifyCharacterDialogClosed" :close-on-click-modal="false">
            <el-form :model="modifyCharacterForm" :rules="modifyCharacterFormRules" ref="modifyCharacterFormRef" label-width="80px" v-loading="EdLoading">
                <el-form-item label="名称：" prop="name">
                    <el-input v-model="modifyCharacterForm.name"></el-input>
                </el-form-item>
                <el-form-item label="描述：" prop="description">
                    <el-input v-model="modifyCharacterForm.description"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="modifyCharacterDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="ModifyCharacterInfo" :disabled="EdLoading">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog  title="分配权限" :visible.sync="setAuthorityDialogVisible" width="40%" @close="CloseSetAuthorityDialog" :close-on-click-modal="false">
            <div style=" height: 60vh; overflow: auto;" >
            <el-tree 
            :data="authoritylist" 
            :props="authorityProps" 
            show-checkbox node-key="id" 
            default-expand-all
            ref="tree"
            :default-checked-keys="defSelectedKeys"
            v-loading="SetLoading"
            >
            </el-tree>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="setAuthorityDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="ModifyAuthorityByCharacter" :disabled="SetLoading">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return{
            charactersList:[],
            addCharacterForm:{
                Name:'',
                Description:''
            },
            addCharacterDialogVisible:false,
            addCharacterFormRules:{
                    Name: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ],
                    Description: [
                        { required: true, message: '请输入描述', trigger: 'blur' }
                    ]
            },
            modifyCharacterDialogVisible:false,
            modifyCharacterForm:{
                    id:0,
                    name:'',
                    description:''
            },
            modifyCharacterFormRules:{
                    name: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ],
                    description: [
                        { required: true, message: '请输入描述', trigger: 'blur' }
                    ]
            },
            setAuthorityDialogVisible:false,
            authorityProps:{
                children:'authorities',
                label:'name'
            },
            authoritylist:[],
            defSelectedKeys:[],
            modifyAuthorityByCharacter:{
                AuthoritiesIDs:[],
                CharacterID:0,
            },
            Loading:false,
            AdLoading:false,
            SetLoading:false,
            EdLoading:false,
            Tag2Loading:false,
        }
    },
    created(){
        this.GetCharactersList()
    },
    methods:{
        async GetCharactersList(){
            this.Loading = true
            const {data:res} = await this.$http.get('Character/v1/Characters')
             this.Loading = false
            if(res.code !== 1){
                return this.$message.error('获取职务列表失败');
            }
            this.charactersList = res.data
        },
        AddCharacterDialogClosed(){
            this.$refs.addCharacterFormRef.resetFields()
        },
        ModifyCharacterDialogClosed(){
            this.$refs.modifyCharacterFormRef.resetFields()
        },
        ShowModifyCharacterDialog(characterinfo){
            let that= this
            // console.log(characterinfo)
            that.modifyCharacterForm.id = characterinfo.id
            that.modifyCharacterForm.name = characterinfo.name
            that.modifyCharacterForm.description = characterinfo.description
            that.modifyCharacterDialogVisible = true
        },
        async ShowSetAuthorityDiaolog(characterinfo){
            let that= this
            that.setAuthorityDialogVisible = true
            that.SetLoading = true
            const {data:res} = await that.$http.get('Authority/v1/AuthoritiesDic')
            that.SetLoading = false
            if(res.code !== 1){
                return that.$message.error('获取职务列表失败');
            }
            that.authoritylist = res.data
            if(characterinfo.authorities.length != 0)
            {
                characterinfo.authorities.forEach(function(item){
                    item.authorities.forEach(function(it){
                        that.defSelectedKeys.push(it.id)
                    })
                });
            }
            that.modifyAuthorityByCharacter.CharacterID = characterinfo.id
        },
        CloseSetAuthorityDialog(){
            this.defSelectedKeys.length = 0
            this.setAuthorityDialogVisible = false
        },
        async DeleteCharacterInfo(id){
            let that = this
            const confirmResult = await that.$confirm('此操作将永远删除该角色, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return that.$message.info('已取消删除')
            }
            that.Loading = true
            await that.$http.delete('Character/v1/CharacterInfo',{params: {id : id }}).
            then(function (response) {
                if(response.data.code != 1)
                {
                    that.$message.info('删除失败')
                }
                else
                {
                    that.$message.success('删除成功')
                }
            }).catch(function (error) {
                that.Loading = false
                return that.$message.info(error)
            });
            that.Loading = false
            that.GetCharactersList()
        },
        async AddCharacterInfo(){
            this.$refs.addCharacterFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            } 
            this.AdLoading = true
            let _this= this
            await this.$http.post('Character/v1/CharacterInfo',this.addCharacterForm).
            then(function (response) {
                if(response.data.code != 1)
                {
                    _this.AdLoading = false
                    return _this.$message.error('添加角色失败')
                }
            }).catch(function (error) {
                 _this.AdLoading = false
                return _this.$message.error(error)
            });
             this.AdLoading = false
            this.$message.success('添加角色成功')
            this.GetCharactersList()
            this.validret = false
            this.addCharacterDialogVisible = false
        },
        async ModifyCharacterInfo(){
            this.$refs.modifyCharacterFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            } 
            this.EdLoading = true
            let _this = this
            await this.$http.put('Character/v1/CharacterInfo',this.modifyCharacterForm).
            then(function (response) {
                if(response.data.code != 1)
                {
                    _this.EdLoading = false
                    return _this.$message.error('修改角色失败')
                }
            }).catch(function (error) {
                _this.EdLoading = false
                return _this.$message.error(error)
            });
             this.EdLoading = false
            this.$message.success('修改角色成功')
            this.GetCharactersList()
            this.validret = false
            this.modifyCharacterDialogVisible = false
        },
        async ModifyAuthorityByCharacter(){
            let that= this
            that.modifyAuthorityByCharacter.AuthoritiesIDs = that.$refs.tree.getCheckedKeys()
            that.SetLoading = true
            await that.$http.put('Authority/v1/AuthoritiesByCharacterID',that.modifyAuthorityByCharacter).
            then(function (response) {
                if(response.data.code != 1)
                {
                    that.$message.info('修改权限失败')
                }
                else
                {
                    that.$message.success('修改权限成功')
                }
            }).catch(function (error) {
                  that.SetLoading = false
                return that.$message.info(error)
            });
              that.SetLoading = false
            that.GetCharactersList()
            that.setAuthorityDialogVisible = false
        },
        async DeleteAuthorityByCharacter(characterID, list, info){
            let that = this
            if(info.authorities.length > 0){
                const confirmResult = await that.$confirm('此操作将删除子节点权限, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).
                catch(err=>err);
                if(confirmResult !== 'confirm'){
                    return that.$message.info('已取消删除')
                }
            }
            that.Tag2Loading = true
            await that.$http.delete('Authority/v1/AuthorityByCharacterID',{params: {characterID : characterID , authorityID:info.id}}).
            then(function (response) {
                if(response.data.code != 1)
                {
                    that.$message.info('删除失败')
                }
                else
                {
                    that.$message.success('删除成功')
                }
            }).catch(function (error) {
                 that.Tag2Loading = false
                return that.$message.info(error)
            });
             that.Tag2Loading = false
            const item = list.indexOf(info)
            list.splice(item, 1)
        }
    }
}
</script>

<style scoped>
.box-card{
    text-align: left;
}

.el-tag{
    margin: 7px;

}

.bdtop{
    border-top: 1px solid#eee;
}

.bdbottom{
    border-bottom: 1px solid#eee;
}

.vcenter{
    display: flex;
    align-items: center;
}
</style>